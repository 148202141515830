<template>
    <v-container fluid class="phones">
      <v-row>
        <v-col offset-lg="1">
          <v-img src="/images/phones.png" width="952" />
        </v-col>
      </v-row>
      
    </v-container>
</template>

<script>
  export default {
    name: 'Phones',

  }
</script>

<style scoped>
  .phones {
    margin-top: 8em;
    background-image: url('/images/splash.png');
    background-position: top right;
    max-height: 731px;
    background-size: contain;
    
  }

  .phones .row {
    margin-top: 5rem;
  }
</style>