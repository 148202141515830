<template>
    <v-container :class="`winners winners-${$vuetify.breakpoint.name}`">
       <h2>{{ title }}</h2>
       <p class="description" v-html="text"></p>

       <v-row class="winners">
         <v-col v-for="prize in prizes" :key="prize.id" md="4" cols="6">
           <h3 class="sharp">{{ prize.name }}</h3>
           <ul v-if="prizeWinners(prize.id).length>0">
             <li v-for="winner in prizeWinners(prize.id)" :key="winner.id">
                {{ winner.twitch_user }}
             </li>
             
           </ul>
           <div v-else>Zatím žádní výherci</div>
         </v-col>
       </v-row>
    </v-container>
</template>

<script>
  import { mapState, mapGetters  } from 'vuex'
  
    
  export default {
    components: { 
  
    },
    name: 'Winners',
    data: () => ({


    }),
    computed: {
      ...mapState({
        // arrow functions can make the code very succinct!
        contest: state => state.contest,
        prizes: state => state.content.prizes,
        winners: state => state.content.winners
      }),
      ...mapGetters({
        getPageContent: 'content/getPageContent'
      }),
      title: function() {
        return this.getPageContent('homepage', 'winners_title')
      },
      text: function() {
        return this.getPageContent('homepage', 'winners_text')
      }
    },
    methods: {
      prizeWinners: function(prize) {
        return this.winners.filter(w => w.prize == prize)
      }
    }
  }
</script>

<style scoped>

    .winners-xl {
      width: 1200px;
    }
    .winners {
      text-align: center;
    }
    .winners p.description {
      margin: 3em;
    }
    .winners h3 {
      margin: 2em 0;
    }
    .winners ul, .winners li {
      list-style: none;
      margin: 0;
      padding: 0;
      line-height: 1.5em;
      
    }
</style>