<template>
  <div :class="`main-container main-container-${$vuetify.breakpoint.name}`" ref="main">
    
      <contest-navbar @linkClick="(id) => scroll(id)" />
      <contest-hero />
      <my-cards v-if="user" />
      <how-to-play />
      <prizes id="prizes" />
      <phones />
      <streamers id="streamers" />
      <winners id="winners" />
      <Footer />
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import ContestNavbar from '../components/Contest/Navbar'
  import ContestHero from '../components/Contest/Hero'
  import MyCards from '../components/Contest/MyCards.vue'
  import HowToPlay from '../components/Contest/HowToPlay.vue'
  import Prizes from '../components/Contest/Prizes.vue'
  import Phones from '../components/Contest/Phones.vue'
  import Streamers from '../components/Contest/Streamers.vue'
  import Winners from '../components/Contest/Winners.vue'
  import Footer from '../components/Contest/Footer.vue'


  export default {
    name: 'Contest',
    data: () => ({
      
    }),
    computed: {
      ...mapState({
        // arrow functions can make the code very succinct!
        user: state => state.user
      }),
    },
    components: {
        ContestNavbar,
        ContestHero,
        MyCards,
        HowToPlay,
        Prizes,
        Phones,
        Streamers,
        Winners,
        Footer
    },
    methods: {
      handleScroll: function() {
        const scrollY = window.scrollY;
        if (this.$refs.main) {
          //  console.log(this.$refs.main.style);
          this.$refs.main.style.backgroundPositionY = Math.round(scrollY / 3) + 'px';
        }
      },
      scroll: function(id) {  
        document.getElementById(id).scrollIntoView({
          behavior: "smooth"
        });
      }
    },
    mounted() {
      this.$store.commit('setContest', this.$route.params.contest || process.env.VUE_APP_STREAMCODE_CONTEST);
      this.$store.dispatch('content/load');
      document.addEventListener('scroll', this.handleScroll)
    },
  }
</script>