<template>
    <v-container :class="`streamers streamers-${$vuetify.breakpoint.name}`">
       <h2>{{ title }}</h2>
       <p class="description" v-html="text"></p>

       <v-row class="" justify="center">
         <v-col v-for="streamer in streamers" :key="streamer.name" class="streamer">
           <div class="photo-holder">
            <v-img :src="streamer.image" class="photo" />
           </div>

           <h3 class="sharp">{{ streamer.name }}</h3>
           <p>{{ streamer.description }}</p>
           <div class="btn-holder">
            <v-btn class="sbtn" outlined elevation="0" :href="`https://twitch.tv/${streamer.twitch_channel}`" target="_blank" rel="noopener">Zjistit více</v-btn>
           </div>
         </v-col>
       </v-row>
    </v-container>
</template>

<script>
  import { mapState, mapGetters  } from 'vuex'

    
  export default {
    components: {  },
    name: 'Streamers',

    data: () => ({


    }),
    computed: {
      ...mapState({
        // arrow functions can make the code very succinct!
        contest: state => state.contest,
        streamers: state => state.content.streamers
      }),
      ...mapGetters({
        getPageContent: 'content/getPageContent'
      }),
      title: function() {
        return this.getPageContent('homepage', 'streamers_title')
      },
      text: function() {
        return this.getPageContent('homepage', 'streamers_text')
      }
    },
  }
</script>

<style scoped>
    .streamers {
      margin-top: 4em;
    }
    .streamers-lg, .streamers-xl {
      margin-top: 4em;
      width: 1200px;
    }
    .streamers p.description {
      margin: 3em;
    }
    .streamer {
      padding: 0 5em;
    }
    .streamer .photo-holder {
      text-align: center;
    }
    .streamer .photo {
      max-width: 420px;
      margin: 0 auto;
    }
    .streamer h3 {
      text-align: center;
      font-size: 2em;
      margin: 0.8em 0;
      font-weight: bold;
    }
    .btn-holder {
      text-align: center;
    }
    .sbtn {
      background-color: white;
      margin: 2rem auto;
      color: black !important;
      border-radius: 2em;
      font-weight: bold;
      font-size: 0.8em;
      text-transform: none;
      padding: 1.2em 1.5em !important;
      
    }

</style>