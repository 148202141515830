<template>
  <v-app-bar
      dark
      class="navbar"
      flat
    >
      <div class="d-flex align-center">
        
        <v-img
          class="logo"
          alt="Samsung logo"
          
          src="/images/logo.png"
          
        />
      </div>

      <v-spacer></v-spacer>
      
      <div class="menu hidden-md-and-down">
        <a v-for="item in wideMenuItems" :key="item.title" @click.prevent="menuClick(item.action)">{{ item.title }}</a>
      </div>
      <v-spacer></v-spacer>

      <twitch-login-button v-if="!user" size="small" class="hidden-md-and-down" />
      <div v-else class="hidden-md-and-down">
        <v-avatar v-if="user.picture" size="2.5rem">
          <img :src="user.picture" :alt="user.preferred_username" />
        </v-avatar>
        <span class="username">
          {{ user.preferred_username }}
        </span>
        <logout-button class="logout" />
      </div>
      <div class="hidden-lg-and-up">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon v-bind="attrs" v-on="on"><v-icon>mdi-menu</v-icon></v-app-bar-nav-icon>
          </template>
          <v-list>
            <v-list-item v-for="item in menu" :key="item.icon">
              <v-list-item-content class="href" @click="menuClick(item.action)">{{ item.title }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
</template>

<script>
  import { mapState } from 'vuex'
  import LogoutButton from '../LogoutButton.vue'
  import TwitchLoginButton from '../TwitchLoginButton.vue'
  
  
  export default {
    components: { TwitchLoginButton, LogoutButton },
    name: 'ContestNavbar',

    data: () => ({
      menu: [
        {title: 'Soutěž', action: 'contest', hide: ['Contest'] },
        {title: 'Pravidla', action: 'rules', hide: ['Rules'] },
        {title: 'Ceny', action: 'prizes' },
        {title: 'Streameři', action: 'streamers' },
        {title: 'Výherci', action: 'winners' },
        {title: 'Odhlásit', action: 'logout', mobile: true, user: true }
      ]
    }),
    computed: {
      ...mapState({
        // arrow functions can make the code very succinct!
        user: state => state.user,
        contest: state => state.contest
      }),
      menuItems: function() {
        let menu = this.menu.filter(m => !m.hide || m.hide.indexOf(this.$route.name)==-1);

        if (this.user) return menu;
        else return menu.filter(m => !m.user);
      },
      wideMenuItems: function() {
        return this.menuItems.filter(m => !m.mobile)
      }
    },
    methods: {
      menuClick: function(action) {
        switch(action) {
          case "rules":
            this.$router.push({name: 'Rules'})
          break;
          case "contest":
            this.$router.push({name: 'Contest', params: { contest: this.contest}});
          break;
          case "logout": 
            this.$store.dispatch('logout');
          break;
          default: 
            this.$emit('linkClick', action)
        }
      }
    }
  }
</script>

<style scoped>
  .logo {
    margin-left: 3rem;
  }
  .logout {
    margin-right: 3rem;
  }
  .navbar {
    background-color: transparent !important;
    padding-top: 1.2rem;
  }
  .menu a {
    position: relative;
    text-decoration: none;
    color: white;
    padding: 1em 3em;
    display: inline-block;
    transition: all 0.5s ease;
    
  }
  .menu a:not(:last-of-type)::after {
    content: "";
    width: 4em;
    height: 1px;
    background-color: white;
    position: absolute;
    top: 1.75em;
    margin-left: 1em;
    transition: all 0.5s ease;
  }
  .menu a:hover {
    text-shadow: rgba(255, 255, 255, 1) 0px 0px 0.2em;
    font-weight: 130%;
    padding: 1em 4em;
  }
  .menu a:hover:not(:last-of-type)::after {
    margin-left: 2em;
  }
  .username {
    padding: 0 1rem;
  }

  .href {
    cursor: pointer;
  }
  .v-menu__content {
    z-index: 300;
  }

  
</style>