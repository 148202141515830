<template>
    <v-container :class="`how-to-play how-to-play-${$vuetify.breakpoint.name}`">
       <h2>{{ title }}</h2>
       <p class="description" v-html="text"></p>

       <v-row class="row categories sm-12 lg-4" justify="center">
         <v-col v-for="category in categories" :key="category.slug" align-self="center" class="card-holder">
           <card-front :img="category.back" zoom  :width="269" :height="425" class="card" />
           <h3 class="sharp">{{ category.name }}</h3>
           <!--<p v-html="category.description"></p>-->
         </v-col>
       </v-row>
    </v-container>
</template>

<script>
  import { mapState, mapGetters  } from 'vuex'
import CardFront from './CardFront.vue'
    
  export default {
    components: {CardFront  },
    name: 'HowToPlay',

    data: () => ({


    }),
    computed: {
      ...mapState({
        // arrow functions can make the code very succinct!
        contest: state => state.contest,
        categories: state => state.content.categories
      }),
      ...mapGetters({
        getPageContent: 'content/getPageContent'
      }),
      title: function() {
        return this.getPageContent('homepage', 'howtoplay_title')
      },
      text: function() {
        return this.getPageContent('homepage', 'howtoplay_text')
      }
    },
  }
</script>

<style scoped>
  .how-to-play-xl {
    width: 1200px;
  }
  .how-to-play p.description {
    margin: 3em;
  }
  .card-holder {
    text-align: center;
  }
  .card-holder .card {
    margin: 0 auto;
  }
  .card-holder h3 {
    font-size: 2em;
    margin: 1em 0;
    font-weight: bold;
  }
</style>