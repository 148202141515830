<template>
  <div>
    <v-btn :href="href" :disabled="loading" :class="`lbtn lbtn-${size}`" color="white">
      <span v-if="loading">Přihlašování ...</span>
      <span v-else>Přihlásit se</span>
    </v-btn>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'TwitchLoginButton',
    props: {
      size: {
        type: String,
        default: 'normal'
      }
    },
    computed: {
      ...mapState({
        // arrow functions can make the code very succinct!
        loading: state => state.loadingUser
      }),
      href: function() {
        return `https://id.twitch.tv/oauth2/authorize?client_id=${this.clientId}&redirect_uri=${this.redirectUrl}&response_type=${this.responseType}&scope=${this.scope}&claims=${this.claims}`
        
      },
      claims: function() {
        return JSON.stringify({ 
            "id_token": { 
                "email": null, 
                "email_verified": null,
                "picture": null, 
                "preferred_username": null 
            } 
        })
      },
      redirectUrl: function() {
        return process.env.VUE_APP_TWITCH_LOGIN_REDIRECT
      }
    },
    data: () => ({
      clientId: process.env.VUE_APP_STREAMCODE_TWITCH_CLIENT_ID,
      responseType: 'token+id_token',
      scope: 'openid user:read:email'
    })
  }
</script>

<style scoped>
  .lbtn {
    color: black;
    border-radius: 2em;
    font-weight: bold;
    font-size: 1.5em;
    text-transform: none;
    padding: 1.2em 1.5em !important;
  }
  .lbtn-small {
    font-size: 1em;
  }
</style>