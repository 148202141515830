<template>
    <v-container :class="`hero hero-${$vuetify.breakpoint.name}`">
        <div class="top">
          <h1>{{ title }}</h1>
          <p>{{ subtitle }}</p>
          <v-row v-if="!user">
            <v-spacer/>
              <v-col>
                <twitch-login-button class="login-button" />
              </v-col>
            <v-spacer/>
          </v-row>
          
        </div>
        <card-parallax class="card left-grey hidden-sm-and-down" :rotateX="-25" :rotateY="25" :rotateZ="25" />
        <card-parallax class="card left-purple hidden-sm-and-down" :rotateX="20" :rotateY="20" :rotateZ="-35" img="https://media.streamcode.win/card_miley_back_3ab4195dc9.svg" />
        <card-parallax class="card left-green hidden-sm-and-down" :rotateX="50" :rotateY="-20" :rotateZ="240" img="https://media.streamcode.win/card_xnapy_back_257b226d6d.svg" />
        <card-parallax class="card right-grey hidden-sm-and-down" :rotateX="25" :rotateY="5" :rotateZ="65" />
        <card-parallax class="card right-purple hidden-sm-and-down" :rotateX="20" :rotateY="20" :rotateZ="-55" img="https://media.streamcode.win/card_miley_back_3ab4195dc9.svg" />
        <card-parallax class="card right-green hidden-sm-and-down" :rotateX="-5" :rotateY="40" :rotateZ="-5" img="https://media.streamcode.win/card_xnapy_back_257b226d6d.svg" />
    </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import TwitchLoginButton from '../TwitchLoginButton.vue'
import CardParallax from './CardParallax.vue'
  
  
  export default {
    components: { TwitchLoginButton, CardParallax },
    name: 'ContestHero',
    props: {
      title: {
        type: String,
        default: '#CatchYourGalaxy'
      },
      subtitle: {
        type: String,
        default: 'Sbírej karty a vyhraj skvělé ceny z řady Samsung Galaxy'
      }
    },
    computed: {
      ...mapState({
        // arrow functions can make the code very succinct!
        user: state => state.user
      }),
    },
  }
</script>

<style scoped>
    .card {
      position: absolute;
      z-index: 3;
    }
    .top {
      position: relative;
      z-index: 5;
    }
    .left-grey {
      top: 24rem;
      left: 8%;
    }

    .left-purple {
      top: 10rem;
      left: 20%;
    }

    .left-green {
      top: 35rem;
      left: 20%;
    }

    .right-grey {
      top: 35rem;
      right: 15%;
    }

    .right-purple {
      top: 26rem;
      right: 8%;
    }

    .right-green {
      top: 5rem;
      right: 20%;
    }

    .hero {
      padding-top: 18rem;
      padding-bottom: 18rem;
      font-size: 1em;
    }
    .hero.hero-lg {
      font-size: 0.8em;
    }
    .hero.hero-md {
      font-size: 0.6em;
    }

    .hero.hero-sm {
      font-size: 0.5em;
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    .hero.hero-xs {
      font-size: 0.3em;
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    .hero.hero-xs h1 {
      font-size: 5em;
    }

    h1 {
        text-align: center;
        font-size: 7em;
        
        font-family: 'SamsungSharpSans', arial,sans-serif;
    }

    
    
    p {
        text-align: center;
        font-size: 2.5em;
    }

    .login-button {
      margin-top: 4em;
      text-align: center;
    }
</style>