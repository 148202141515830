<template>
  <div :class="`main-container main-container-${$vuetify.breakpoint.name}`" ref="main">
    
      <contest-navbar @linkClick="(id) => scroll(id)" />
      <contest-hero subtitle="Pravidla soutěže" />

      <v-container :class="`rules rules-${$vuetify.breakpoint.name}`" v-html="content">
      
      </v-container>
      
      <Footer />
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import ContestNavbar from '../components/Contest/Navbar'
  import ContestHero from '../components/Contest/Hero'
  import Footer from '../components/Contest/Footer.vue'


  export default {
    name: 'Contest',
    data: () => ({
      content: null
    }),
    computed: {
      ...mapState({
        // arrow functions can make the code very succinct!
        user: state => state.user,
        contest: state => state.contest
      }),
    },
    components: {
        ContestNavbar,
        ContestHero,
        Footer
    },
    methods: {
      handleScroll: function() {
        const scrollY = window.scrollY;
        if (this.$refs.main) {
          //  console.log(this.$refs.main.style);
          this.$refs.main.style.backgroundPositionY = Math.round(scrollY / 3) + 'px';
        }
      },
      scroll: function(id) {  
        document.getElementById(id).scrollIntoView({
          behavior: "smooth"
        });
      },
      load: function() {
        this.$api.get(`/content/${this.contest}/rules`)
                .then(res => res.data)
                .then(data => this.content = data.content)
      }
    },
    mounted() {
      this.$store.commit('setContest', this.$route.params.contest || process.env.VUE_APP_STREAMCODE_CONTEST);
      this.load();
      document.addEventListener('scroll', this.handleScroll)
    },
  }
</script>