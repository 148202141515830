<template>
    <v-container class="footer" fluid ref="footerContainer" id="footerContainer">
        <div class="footer-splash">
            <v-img src="/images/phones-bottom.png" width="662" class="phones-bottom" />
            <h2>{{ title }}</h2>
            <card-parallax class="card left-grey hidden-sm-and-down" :rotateX="-25" :rotateY="25" :rotateZ="25" :parallax="false" />
            <card-parallax class="card left-purple hidden-sm-and-down" :rotateX="20" :rotateY="20" :rotateZ="-35" :parallax="false" img="https://media.streamcode.win/card_miley_back_3ab4195dc9.svg" />
            <card-parallax class="card left-green hidden-sm-and-down" :rotateX="50" :rotateY="-20" :rotateZ="240" :parallax="false" img="https://media.streamcode.win/card_xnapy_back_257b226d6d.svg" />
            <card-parallax class="card right-grey hidden-sm-and-down" :rotateX="25" :rotateY="5" :rotateZ="65" :parallax="false" />
            <card-parallax class="card right-purple hidden-sm-and-down" :rotateX="20" :rotateY="20" :rotateZ="-55" :parallax="false" img="https://media.streamcode.win/card_miley_back_3ab4195dc9.svg" />
            <card-parallax class="card right-green hidden-sm-and-down" :rotateX="-5" :rotateY="40" :rotateZ="-5" :parallax="false" img="https://media.streamcode.win/card_xnapy_back_257b226d6d.svg" />
        </div>
        <div class="logo">
            <v-img
                alt="Samsung logo"
                src="/images/logo.png"
                width="125"
                class="logo-bottom"
            />
        </div>
        <div class="copyright">
            {{ copy }}
        </div>
    </v-container>
</template>

<script>
  
import CardParallax from './CardParallax.vue'
  
  
  export default {
    components: { CardParallax },
    name: 'ContestFooter',

    data: () => ({
        copy: 'Copyright@ 2021 Samsung. Všechna práva vyhrazena.',
        title: '#CatchYourGalaxy',
    }),

    

  }
</script>

<style scoped>
    .footer {
        margin-top: 8em;
        text-align: center;
    }
    .card {
      position: absolute;
      z-index: 50;
    }
    .top {
      position: relative;
      z-index: 100;
    }
    .left-grey {
      top: 15rem;
      left: 10%;
    }

    .left-purple {
      top: 2rem;
      left: 20%;
    }

    .left-green {
      top: 30rem;
      left: 25%;
    }

    .right-grey {
      top: 30rem;
      right: 25%;
    }

    .right-purple {
      top: 15rem;
      right: 15%;
    }

    .right-green {
      top: 2rem;
      right: 20%;
    }

    .footer-splash {
        position: relative;
      padding-top: 5rem;
      padding-bottom: 2rem;
      text-align: center;
    }
    h1 {
        text-align: center;
        font-size: 7em;
        
        font-family: 'SamsungSharpSans', arial,sans-serif;
    }
    p {
        text-align: center;
        font-size: 2.5em;
    }
    .phones-bottom {
        margin: 0 auto;
    }
    .logo-bottom {
        margin: 0 auto;
        margin-bottom: 6rem;
    }

    .copyright {
      color: #747474;
      font-size: 0.8em;
      padding-bottom: 3rem;
    }
</style>