<template>
  <v-btn color="red" @click="logout" :class="`lbtn`" outlined>Odhlásit</v-btn>
</template>

<script>
  export default {
    name: 'LogoutButton',
    methods: {
      logout: function() {
        this.$store.dispatch('logout');
      }
    }
  }
</script>

<style scoped>
  .lbtn {
    color: red;
    border-radius: 2em;
    font-weight: bold;
    font-size: 1.5em;
    text-transform: none;
    padding: 1.2em 1.5em !important;
  }
</style>