<template>
    <div class="card-front-holder" :style="containerStyle" ref="card">
        <div class="card-front-inner" v-bind:style="cardStyle">
            <v-img :src="img" :width="width" :height="height" />
        </div>
    </div>
</template>

<script>
export default {
    components: {  },
    name: 'ContestCardParallax',
    props: {
        width: {
            type: Number,
            default: 171
        },
        height: {
            type: Number,
            default: 270
        },
        img: {
            type: String,
            default: 'https://media.streamcode.win/card_samsung_back_88610ef17a.svg'
        },
        rotateX: {
            type: Number,
            default: 0
        },
        rotateY: {
            type: Number,
            default: 0
        },
        rotateZ: {
            type: Number,
            default: 0
        },
        scale: {
            type: Number,
            default: 1
        },
        start: {
            type: Number,
            default: 0
        },
        end: {
            type: Number,
            default: 1000
        },
        parallax: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        perspective: 1000,
        yStart: 0,
        yEnd: 1000,
        offsetX: 0,
        offsetY: 0,
        rotateXStart: 0,
        rotateYStart: 0,
        rotateZStart: 0,
        rotateXEnd: 0,
        rotateYEnd: 0,
        rotateZEnd: 0,
        currentX: 0,
        currentY: 0,
        currentZ: 0
    }),
    computed: {
        containerStyle:  function () {
            return {
                    perspective: this.perspective+'px',
                    
            };
		},
		cardStyle : function () {
            return {
                transform: 'rotateX('+this.currentX+'deg) rotateY('+this.rotateY+'deg) rotateZ('+this.rotateZ+'deg) scale('+this.scale+')',
                
            };
		}    
    },
    methods: {
        handleScroll: function() {
            const scrollY = window.scrollY;
            if (this.$refs.card && this.parallax) {
                if (scrollY > this.start && scrollY < this.end) {
                    let x = (100/this.end * scrollY)/100;
                    this.currentX = this.rotateXStart - (100*x);
                    this.currentY = this.rotateYStart - (100*x);
                    this.currentZ = this.rotateZStart + (100*x);
                }
            //  console.log(this.$refs.main.style);
            //this.$refs.main.style.backgroundPositionY = Math.round(scrollY / 3) + 'px';
            }
        }
    },
    mounted() {
        this.currentX = this.rotateX;
        this.currentY = this.rotateY;
        this.currentZ = this.rotateZ;

        this.rotateXStart = this.rotateX;
        this.rotateYStart = this.rotateY;
        this.rotateZStart = this.rotateZ;

        this.rotateXEnd = this.rotateX + 50;
        this.rotateYEnd = this.rotateY + 50;
        this.rotateZEnd = this.rotateZ + 50;

        document.addEventListener('scroll', this.handleScroll)
    },
  }
</script>

<style scoped>
   
</style>