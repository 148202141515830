<template>
    <v-container :class="`my-cards my-cards-${$vuetify.breakpoint.name}`">
        <v-row>
            <v-col>
                <h2>Moje karty</h2>
            </v-col>
            <v-col class="my-cards-add-holder">
                <v-btn @click="modal = true" class="sbtn" color="black" outlined dark>Přidat kartu</v-btn>
            </v-col>
        </v-row>

            <v-row class="sm-12 lg-2">
                <v-col v-for="card in allcards" :key="card.key">
                    <contest-card-front v-if="card.card" zoom :img="card.img" :card="card.card" />
                    <contest-card-front v-else :img="card.img" />
                </v-col>
            </v-row>  

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="modal"
                light
            >
                
                <v-card>
                    <h3>Vlož kód karty</h3>
                    
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-alert v-if="codeError" type="error" dense outlined>{{ codeError }}</v-alert>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="Kód"
                                    placeholder="ABCDEFG"
                                    filled
                                    v-model="code"
                                    :rules="[validCode]"
                                    :disabled="adding"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" >
                                <vue-recaptcha 
                                    ref="recaptcha"
                                    sitekey="6Lfzvh4bAAAAAG9hIh19-MFU-reWImgtGXlu6GFI" 
                                    :loadRecaptchaScript="true"
                                    @verify="onRecaptchaVerify"
                                    @expired="onRecaptchaExpired"
                                    style="float: right"
                                >
                                </vue-recaptcha>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                                <v-btn
                                    text
                                    @click="submitCode"
                                    :disabled="!canSubmit"
                                ><span v-if="adding">Ověřuji kód karty</span><span v-else>Přidat kartu</span></v-btn>
                    </v-card-actions>
                </v-card>
                
            </v-dialog>
          
      </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import ContestCardFront from '@/components/Contest/CardFront'
  import VueRecaptcha from 'vue-recaptcha';
  
  export default {
    components: {ContestCardFront, VueRecaptcha },
    name: 'MyCards',

    data: () => ({
        modal: false,
        recaptchaKey: null,
        codeLength: 7,
        code: null,
        codeError: null,
        adding: false
    }),
    computed: {
      ...mapState({
        // arrow functions can make the code very succinct!
        user: state => state.user,
        cards: state => state.cards,
        contest: state => state.contest,
        categories: state => state.content.categories
      }),
      canSubmit: function() {
          return !this.adding && this.recaptchaKey && this.code && this.code.length == this.codeLength;
      },
      validCode: function() {
          if (this.code) {
              if (this.code.length < this.codeLength) return 'Zadaný kód je příliš krátký';
              else if (this.code.length > this.codeLength) return 'Neplatný kód';
              else return true;
          }
          else {
              return 'Zadej kód';
          }
      },
      allcards: function() {
          let cards = [];

          for (let category of this.categories) {
              for (let categoryCard of this.categoryCards(category.id)) {
                  cards.push({
                      img: category.front,
                      card: categoryCard,
                      key: categoryCard.code

                  })
              }
              for (let i = 1; i<= this.categoryLeft(category.id); i++) {
                cards.push({
                      img: category.back,
                      i: i,
                      key: `${category.id}-${i}`
                  })  
              }
          }

          return cards;
      }
    },
    methods: {
        
        onRecaptchaVerify: function(event) {
            this.recaptchaKey = event;
        },
        onRecaptchaExpired: function() {
            this.recaptchaKey = null;
        },
        submitCode: function() {
            this.codeError = null;
            this.adding = true;
            // api request
            this.$api.post(`/code/${this.contest}`, {
                token: this.recaptchaKey,
                code: this.code
            })
            .then(res => res.data)
            .then(res => {
                if (res.error) {
                    switch(res.error) {
                        case "duplicate":
                            this.codeError = 'Kartu pod tímto kódem máš již ve sbírce!'
                        break;
                        case "card_not_found":
                            this.codeError = 'Pod daným kódem nebyla nalezena žádná karta. Karty lze přidat do 15 minut po zobrazení na streamu.'
                        break;
                        case "captcha":
                            this.codeError = 'Odeslaná captcha je neplatná, zkus kód odeslat znovu PepeHands'
                        break;
                        default:
                            this.codeError = res.error
                    }
                    this.$gtag.exception({
                        'description': this.codeError,
                        'fatal': false
                    })
                }
                else if (res.card) {
                    this.$store.commit('addCard', res.card);
                    this.recaptchaKey = null;
                    this.code = null;
                    this.modal = false;
                    this.$gtag.event('card_add_success', {
                        event_label: res.card.name,
                        event_category: 'card_add'
                    })
                }
                this.adding = false;
                this.$refs.recaptcha.reset();
            })
            .catch(err => {
                console.log({error: err});
                this.codeError = 'Nastala chyba při komunikaci se serverem FeelsBadMan'
                this.$gtag.exception({
                    'description': `POST /code/${this.contest}: ${err}`,
                    'fatal': false
                })
                this.adding = false;
                this.$refs.recaptcha.reset();
            })


            // result

            // cleanup
            
        },
        categoryCards: function(category) {
            return this.cards.filter(c => c.card_category == category)
        },
        categoryLeft: function(category) {
          return 6 - this.cards.filter(c => c.card_category == category).length;
        
      }
    }
  }
</script>

<style scoped>
    .my-cards {
        margin-bottom: 10em;
    }
    .my-cards-xs {
        margin-bottom: 4em;
    }
    .my-cards-xl {
       width: 1200px;
    }
    .my-cards h2 {
        text-align: left;
    }
    .v-card h3 {
        padding: 1rem;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
    }
    
    .v-card__actions > .v-btn.v-btn {
        border: 1px solid black;
        border-radius: 2em;
        font-weight: bold;
        padding: 1em 2em;
    }
    .v-dialog > .v-card > .v-card__actions {
        padding-bottom: 2em;
        padding-right: 2em;
    }
    .my-cards-add-holder {
        text-align: right;
    }

    .sbtn {
        margin-top: 1em;
      color: white !important;
      border-radius: 2em;
      font-weight: bold;
      font-size: 1em;
      text-transform: none;
      padding: 1.2em 1.5em !important;
    }
    
</style>