<template>
    <v-container :class="`prizes prizes-${$vuetify.breakpoint.name}`">
       <h2>{{ title }}</h2>
       <p class="description" v-html="text"></p>


       

       <v-row class="prizes-description" justify="center">
         <v-col v-for="prize in prizes" :key="prize.id" class="prize-col prize" sm="6" md="4" lg="2" cols="12">
           <div class="prize-image-holder">
              
                <v-img :src="prize.image" width="132" class="prize-image" contain />
              
           </div>
           <h3 class="sharp">{{ prize.quantity }}x<br />{{ prize.name }}</h3>
           <p class="prize-description">{{ prize.description }}</p>

            <!--
           <div class="btn-holder" v-if="!expand[prize.name]">
            <v-btn class="mbtn" color="black" outlined dark @click="$set(expand, prize.name, !expand[prize.name])">Zjistit více</v-btn>
           </div>
           <v-expand-transition>
             <div v-show="expand[prize.name]">
                <h4>Technické specifikace</h4>
                <v-row v-for="parameter in prize.parameters" :key="parameter.name">
                  <v-col cols="5">{{ parameter.name }}:</v-col>
                  <v-col  v-if="parameter.value.indexOf('#')==0"><product-colors :colors="parameter.value" :size="24" /></v-col>
                  <v-col v-else>{{ parameter.value }}</v-col>
                </v-row>
                
             </div>
           </v-expand-transition>
          -->
          <div class="btn-holder">
            <v-btn :href="prize.url" target="_blank" noopener class="mbtn" color="black" outlined dark>Zjistit více</v-btn>
          </div>
         </v-col>
       </v-row>
    </v-container>
</template>

<script>
  import { mapState, mapGetters  } from 'vuex'
  /*import ProductColors from './ProductColors.vue'*/
    
  export default {
    components: { 
      /*ProductColors*/
    },
    name: 'Prizes',
    data: () => ({
      expand: {}
    }),
    computed: {
      ...mapState({
        // arrow functions can make the code very succinct!
        contest: state => state.contest,
        prizes: state => state.content.prizes
      }),
      ...mapGetters({
        getPageContent: 'content/getPageContent'
      }),
      title: function() {
        return this.getPageContent('homepage', 'prize_title')
      },
      text: function() {
        return this.getPageContent('homepage', 'prize_text')
      }
    },
  }
</script>

<style scoped>
    .prizes {
      margin-top: 4em;
    }
    .prizes-xl {
      width: 1400px;
    }

    
    .prize-col {
      padding: 0 2em;
    }

    .prizes-xl .prize-col, .prizes-lg .prize-col {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .prizes-xs .prize-col {
      padding: 0 5em;
      margin-bottom: 5em;
    }

    
      

    .prize-image-holder {
      height: 237px;
      width: 140px;
      text-align: center;
      position: relative;
    }

    .prizes-xs .prize-col .prize-image-holder {
      left: 33%;
    }

    .prizes-xs .btn-holder {
      text-align: center;
    }
    
    .prize-image {
      position: absolute;
      
      bottom: 0;
      
      
    }
    .prizes-description {
      font-size: 0.9em;
      
    }
    .prize-description {
      text-align: left;
      margin-bottom: 3em;
      min-height: 6em;
    }
    .prize h3 {
      font-size: 1.8rem;
      margin: 0.6em 0;
      line-height: 1.3em;
      font-weight: bold;
      min-height: 4em;
    }
    .prizes-sm .prize h3, .prizes-xs .prize h3 {
      min-height: 2em;
    }
    .prizes-sm .prize-description, .prizes-xs .prize-description {
      min-height: 2em;
    }

    .prize h4 {
      text-decoration: underline;
      font-weight: normal;
    }

    .prizes p.description {
      margin: 3em;
    }
    .mbtn {
      margin-top: 0em;
      color: white !important;
      border-radius: 2em;
      font-weight: bold;
      font-size: 1em;
      text-transform: none;
      padding: 1.2em 1.5em !important;
      
    }

</style>